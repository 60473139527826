import Abele from 'abele'
// import InstagramFeed from './InstagramFeed'
import './Footer.css'

export default () => (
  <div>
    {/* <h2 className="taCenter"> */}
    {/* Follow us{' '} */}
    {/* <a href="https://instagram.com/thrivegoldcoast/">@thrivegoldcoast</a> */}
    {/* </h2> */}
    {/* <InstagramFeed count="8" /> */}
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} SOP. Tutti i diritti riservati.
        </span>
      </div>
    </footer>
  </div>
)
