import Abele, { Component } from 'abele'
import { Location } from '@reach/router'
import { Link } from 'drinso'
import { Menu, X } from 'abele-feather'

import Logo from './Logo'
import './Nav.css'

const FacebookIcon = () => (
  // <img
  //   alt=""
  //   src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHsSURBVGhD7ZjNLgNhGEZ7ZS1i5a/TEvdDLCywE0sXoTMSEZZW4t8FWEqIlg0Lo41nIprzxfv1pWMxJzm7d56e9GfTWkVFRcX/ptFMk3orPa63Oi+NVpqPw8Frfb7m3oIyRqORpOv0AmN2TTlxTCSdJoyVYr2ZzSvLzuAjpLFSTNIjZdkZ53feYE9ZdmCkVJVlh0bKVFl2aMTr1FKWb+xc5efXD3m395q/v+ffoGcKlWWHRjxOLmb5yem9Uhl6rlBZdmjE42b/nf8Jeq5QWXZoxOPFzYMyw9BzhcqyQyMeu89vyvxidessn17ex/thlWWHRjwO/2AHTLYzvCWVZYdGPBJ0F1JZdmjEI0F3IZVlh0ZijeGp+4obhcqyQyOxxnB5+4gbhcqyQyOxxrB3cIcbhcqyQyOxxrC9e4sbhcqyQyMeCboLqSw7NOKRoLuQyrJDIx4JugupLDs04pGgu5DKskMjHgm6C6ksOzTikaC7kMqyQyMeCboLqSw7NOKRoLuQyrJDIx4JugupLDs04pGgu5DKskMjHgm6C6ksOzTikaC7kMqy89t/LRJ0F3CEvxaT9AiGynGkP3eb2TyOleBEqzOrrDj6D68Nj5XginJGo//xzenr1Bsa/kt7/d/gYb2dzSijoqKi4l9Sq30A+M9r/MIZv3oAAAAASUVORK5CYII="
  // />
  <svg
    style={{ width: '25px', height: 'auto', marginTop: '8px' }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 72 72"
  >
    <path
      d="M68 0H4a4 4 0 0 0-4 4v64a4 4 0 0 0 4 4h34.46V44.16h-9.35v-10.9h9.35v-8c0-9.3 5.68-14.37 14-14.37a77.46 77.46 0 0 1 8.38.43V21H55.1c-4.51 0-5.39 2.15-5.39 5.3v6.94H60.5l-1.4 10.9h-9.39V72H68a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z"
      fill="#4267b2"
    />
    <path
      d="M49.71 72V44.16h9.39l1.4-10.9H49.71v-6.94c0-3.15.88-5.3 5.39-5.3h5.72V11.3a77.46 77.46 0 0 0-8.38-.43c-8.3 0-14 5.07-14 14.37v8h-9.33v10.9h9.35V72z"
      fill="#fff"
    />
  </svg>
)

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }

  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      // { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
        <div className="Nav--Container container">
          <Link to="/" onClick={this.handleLinkClick}>
            <Logo />
          </Link>
          <div className="Nav--Links">
            <NavLink to="/">Home</NavLink>
            {/* <NavLink to="/components/">Il SOP</NavLink> */}
            {/* <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('posts')}
              >
                Blog
              </span>
              <div className="Nav--GroupLinks">
                <NavLink to="/blog/" className="Nav--GroupLink">
                  All Posts
                </NavLink>
                {subNav.posts.map((link, index) => (
                  <NavLink
                    to={link.slug}
                    key={'posts-subnav-link-' + index}
                    className="Nav--GroupLink"
                  >
                    {link.title}
                  </NavLink>
                ))}
              </div>
            </div> */}
            <NavLink to="/sop/">Il SOP</NavLink>
            <NavLink to="/eliomarialeone/">Elio M. Leone</NavLink>
            <NavLink to="/osteopatia/">Osteopatia</NavLink>
            <NavLink to="/trattamento/">Trattamento</NavLink>
            <NavLink to="/contatti/">Contatti</NavLink>
            <a
              className="NavLink"
              target="_blank"
              href="https://www.facebook.com/StudioOsteopaticoPrimo/"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
